import slugify, { isValidSlug } from '../../../../../../common/slug';
import { isOutOfBoundsInt, isTooLongStringInput, isValidUrl } from '../../../../../../common/validations';
import isEmptyString from '../../../../../../common/is-empty-string';
import { InfoFormProblem } from '../../SubmitGame/submission-errors';

export interface InfoFormData {
  name: string | null;
  category: string | null;
  tags: string[];
  description: string | null;
  controls: string | null;
  playUrl: string | null;
  appstoreUrl: string | null;
  steamUrl: string | null;
  playStoreDownloads: string | null; // will be converted to number before sending to server
  appStoreDownloads: string | null; // will be converted to number before sending to server
  steamDownloads: string | null; // will be converted to number before sending to server
}

export const MAX_DESCRIPTION_LENGTH = 6000;
export const MAX_CONTROLS_LENGTH = 3500;

export function buildDefaultInfoFormData(): InfoFormData {
  return {
    name: null,
    category: null,
    tags: [],
    description: null,
    controls: null,
    playUrl: null,
    appstoreUrl: null,
    steamUrl: null,
    playStoreDownloads: null,
    appStoreDownloads: null,
    steamDownloads: null,
  };
}

export function validateInfoForm(form: InfoFormData): InfoFormProblem[] {
  const problems: InfoFormProblem[] = [
    ...validateName(form),
    ...validateCategory(form.category),
    ...validateTags(form.tags),
    ...validateDescription(form.description),
    ...validateControls(form.controls),
    ...validatePlayUrl(form.playUrl),
    ...validateSteamUrl(form.steamUrl),
    ...validateAppstoreUrl(form.appstoreUrl),
    ...validatePlayStoreDownloads(form.playStoreDownloads),
    ...validateAppStoreDownloads(form.appStoreDownloads),
    ...validateSteamDownloads(form.steamDownloads),
  ];
  return problems;
}

export function validateCategory(category: string | null): InfoFormProblem[] {
  if (isEmptyString(category)) {
    return ['CATEGORY_MISSING'];
  }
  return [];
}

export function validateTags(tags: string[]): InfoFormProblem[] {
  if (tags.length < 1) {
    return ['TAG_MISSING'];
  }
  return [];
}

export function validateDescription(description: string | null): InfoFormProblem[] {
  if (isEmptyString(description)) {
    return ['DESCRIPTION_MISSING'];
  }
  if (description!.length > MAX_DESCRIPTION_LENGTH) {
    return ['DESCRIPTION_TOO_LONG'];
  }
  return [];
}

export function validateControls(controls: string | null): InfoFormProblem[] {
  if (isEmptyString(controls)) {
    return ['CONTROLS_MISSING'];
  }
  if (controls!.length > MAX_CONTROLS_LENGTH) {
    return ['CONTROLS_TOO_LONG'];
  }
  return [];
}

export function validatePlayUrl(playUrl: string | null): InfoFormProblem[] {
  if (!isEmptyString(playUrl) && (isTooLongStringInput(playUrl!) || !isValidUrl(playUrl as string))) {
    return ['PLAY_URL_INVALID'];
  }
  return [];
}

export function validateAppstoreUrl(appstoreUrl: string | null): InfoFormProblem[] {
  if (!isEmptyString(appstoreUrl) && (isTooLongStringInput(appstoreUrl!) || !isValidUrl(appstoreUrl as string))) {
    return ['APPSTORE_URL_INVALID'];
  }
  return [];
}

export function validateAppStoreDownloads(downloads: string | null): InfoFormProblem[] {
  if (!isEmptyString(downloads) && (isNaN(parseInt(`${downloads}`)) || isOutOfBoundsInt(parseInt(`${downloads}`)))) {
    return ['APP_STORE_DOWNLOADS_INVALID'];
  }
  return [];
}

export function validatePlayStoreDownloads(downloads: string | null): InfoFormProblem[] {
  if (!isEmptyString(downloads) && (isNaN(parseInt(`${downloads}`)) || isOutOfBoundsInt(parseInt(`${downloads}`)))) {
    return ['PLAY_STORE_DOWNLOADS_INVALID'];
  }
  return [];
}

export function validateSteamDownloads(downloads: string | null): InfoFormProblem[] {
  if (!isEmptyString(downloads) && (isNaN(parseInt(`${downloads}`)) || isOutOfBoundsInt(parseInt(`${downloads}`)))) {
    return ['STEAM_DOWNLOADS_INVALID'];
  }
  return [];
}

export function validateSteamUrl(steamUrl: string | null): InfoFormProblem[] {
  if (!isEmptyString(steamUrl) && (isTooLongStringInput(steamUrl!) || !isValidUrl(steamUrl as string))) {
    return ['STEAM_URL_INVALID'];
  }
  return [];
}

export function validateName(form: InfoFormData): InfoFormProblem[] {
  const { name } = form;
  if (isEmptyString(name)) {
    return ['NAME_MISSING'];
  }
  const trimmed = (name as string).trimLeft();
  const slug = slugify(trimmed);
  if (!isValidSlug(slug)) {
    return ['NAME_INVALID'];
  }
  return [];
}
