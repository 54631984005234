import React from 'react';
import { Grid, Tooltip } from '@mui/material';
import CategorySelector from './CategorySelector';
import TagsSelector from './TagsSelector';
import { GameTag, GameCategory } from './gameinfoform-graphql';
import { MAX_NO_TAGS } from '../GameFilesForm/FilesFormData';
import { classes } from '../styledSubmission';
import { StyledBodyText, StyledHeaderText } from '../../../../../../common/Styleguide/Common/Text';
import { StyledInput } from '../../../../../../common/Styleguide/Common/Input';
import { MAX_SLUG_LENGTH } from '../../../../../../common/slug';
import withTheme, { WithTheme } from '../../../../../helpers/WithTheme';
import { COLORS } from '../../../../../../common/Styleguide/Common/colors';
import { StyledContainer } from '../../../../../../common/Styleguide/Common/Container';
import { Add, Help, Remove } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import withSubmission, { WithSubmission } from '../Context/withSubmission';
import { valueFromInput } from '../../../../../../common/input';
import { HALF_INPUT_WIDTH } from '../UpdateSubmission/UpdateSubmission.types';
import { MAX_CONTROLS_LENGTH, MAX_DESCRIPTION_LENGTH } from './InfoFormData';

export interface Props {
  tags: GameTag[];
  isReleased?: boolean;
  categories: GameCategory[];
  attemptedToSave?: boolean;
}

type _Props = Props & WithTheme & WithSubmission;

interface State {
  isCollapsed: boolean;
}

/**
 * Renders the info form fields (game name, category, tags, description, controls, etc).
 * Updates this data, and uses it directly from Submission context.
 */
class GameInfoForm extends React.Component<_Props, State> {
  constructor(props: _Props) {
    super(props);
    this.state = {
      isCollapsed: !!props.isReleased,
    };
  }

  render() {
    const { isReleased } = this.props;
    const { isCollapsed } = this.state;

    return (
      <StyledContainer
        sx={{
          width: 900,
          m: 2,
          p: 2,
          px: 3,
        }}
      >
        {isReleased ? (
          this.renderReleasedGameHeader()
        ) : (
          <StyledHeaderText
            variant="h2"
            sx={{
              pb: 1,
              m: 0,
            }}
          >
            Game details
          </StyledHeaderText>
        )}
        {!isCollapsed && (
          <>
            {this.renderName()}
            {this.renderCategory()}
            {this.renderDescription()}
            {this.renderOtherPlatforms()}
          </>
        )}
      </StyledContainer>
    );
  }

  private renderReleasedGameHeader() {
    const { isCollapsed } = this.state;
    return (
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <StyledHeaderText
          variant="h2"
          sx={{
            m: 0,
          }}
        >
          Game details
        </StyledHeaderText>
        <StyledBodyText sx={{ m: 0.5, flex: 1, ml: 4 }} variant="bodyLower2" color="white80">
          You can't update this section. Feel free to <Link to="/support">contact us</Link> if you have any question.
        </StyledBodyText>
        {isCollapsed ? (
          <Add
            fontSize="large"
            sx={{ '&:hover': { cursor: 'pointer' } }}
            onClick={() =>
              this.setState({
                isCollapsed: false,
              })
            }
          />
        ) : (
          <Remove
            fontSize="large"
            sx={{ '&:hover': { cursor: 'pointer' } }}
            onClick={() =>
              this.setState({
                isCollapsed: true,
              })
            }
          />
        )}
      </div>
    );
  }

  private renderName() {
    const { name } = this.props.infoForm;
    const { isReleased, infoFormProblems, attemptedToSave = false } = this.props;
    return (
      <>
        <StyledBodyText variant="h3" sx={{ marginY: 1.25 }} color="white80">
          Game Title <span>*</span>
        </StyledBodyText>
        <StyledInput
          value={name || ''}
          error={infoFormProblems?.includes('NAME_INVALID') || (infoFormProblems?.includes('NAME_MISSING') && attemptedToSave)}
          disabled={isReleased}
          required
          onChange={this.handleNameChange}
          style={{ width: HALF_INPUT_WIDTH }}
        />
        {infoFormProblems?.includes('NAME_INVALID') && (
          <StyledBodyText sx={{ m: 0.5 }} variant="bodyLower2" color="error">
            {`Max length must be lower than ${MAX_SLUG_LENGTH} chars `}
          </StyledBodyText>
        )}
        {infoFormProblems?.includes('NAME_MISSING') && attemptedToSave && (
          <StyledBodyText sx={{ m: 0.5 }} variant="bodyLower2" color="error">
            {`Please fill in the input `}
          </StyledBodyText>
        )}
        {!isReleased && (
          <StyledBodyText sx={{ m: 0.5 }} variant="bodyLower2" color="white30">
            {`Must be the same as the title that appears in your game - Max length is ${MAX_SLUG_LENGTH} chars.`}
          </StyledBodyText>
        )}
      </>
    );
  }

  private handleNameChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const name = valueFromInput(evt);
    this.props.updateInfoForm({ ...this.props.infoForm, name });
  };

  private renderOtherPlatforms() {
    const { playUrl, appstoreUrl, steamUrl, playStoreDownloads, appStoreDownloads, steamDownloads } = this.props.infoForm;
    const { isReleased, infoFormProblems } = this.props;
    const invalidPlayUrl = infoFormProblems?.includes('PLAY_URL_INVALID');
    const invalidStoreUrl = infoFormProblems?.includes('APPSTORE_URL_INVALID');
    const invalidSteamUrl = infoFormProblems?.includes('STEAM_URL_INVALID');
    const invalidPlayStoreDownloads = infoFormProblems?.includes('PLAY_STORE_DOWNLOADS_INVALID');
    const invalidAppStoreDownloads = infoFormProblems?.includes('APP_STORE_DOWNLOADS_INVALID');
    const invalidSteamDownloads = infoFormProblems?.includes('STEAM_DOWNLOADS_INVALID');

    return (
      <Grid container direction="row" spacing={2}>
        <Grid container item className={`${classes.leftColumn} ${classes.thirdColumn}`} xs={12} md={4} alignContent={'flex-start'}>
          <Grid item xs={12}>
            <StyledBodyText variant="h3" sx={{ marginY: 1.25 }} color="white80">
              Google Play Store link
            </StyledBodyText>
            <StyledInput
              type="url"
              defaultValue={playUrl}
              error={invalidPlayUrl}
              disabled={isReleased}
              required={false}
              onChange={this.handlePlayUrlChange}
            />
            {invalidPlayUrl && (
              <StyledBodyText sx={{ fontSize: 12, m: 0.5 }} variant="bodyLower" color="error">
                {`Invalid Google Play Store link`}
              </StyledBodyText>
            )}
          </Grid>
          <Grid item xs={12}>
            <StyledBodyText variant="h3" sx={{ marginY: 1.25, display: 'flex' }} color="white80">
              Approximate # downloads
              <Tooltip title={'Games with a high number of downloads are eligible for custom contractual terms'}>
                <Help sx={{ width: 20, ml: 0.5 }} />
              </Tooltip>
            </StyledBodyText>
            <StyledInput
              defaultValue={playStoreDownloads}
              error={invalidPlayStoreDownloads}
              disabled={isReleased}
              required={false}
              onChange={this.handlePlayStoreDownloadsChange}
            />
            {invalidPlayStoreDownloads && (
              <StyledBodyText sx={{ fontSize: 12, m: 0.5 }} variant="bodyLower" color="error">
                {`Invalid downloads number`}
              </StyledBodyText>
            )}
          </Grid>
        </Grid>
        <Grid container item className={`${classes.leftColumn} ${classes.thirdColumn}`} xs={12} md={4} alignContent={'flex-start'}>
          <Grid item xs={12}>
            <StyledBodyText variant="h3" sx={{ marginY: 1.25 }} color="white80">
              iOS App Store link
            </StyledBodyText>
            <StyledInput
              type="url"
              defaultValue={appstoreUrl}
              error={invalidStoreUrl}
              disabled={isReleased}
              required={false}
              onChange={this.handleAppstoreUrlChange}
            />
            {invalidStoreUrl && (
              <StyledBodyText sx={{ fontSize: 12, m: 0.5 }} variant="bodyLower" color="error">
                {`Invalid IOS App Store link`}
              </StyledBodyText>
            )}
          </Grid>
          <Grid item xs={12}>
            <StyledBodyText variant="h3" sx={{ marginY: 1.25, display: 'flex' }} color="white80">
              Approximate # downloads
              <Tooltip title={'Games with a high number of downloads are eligible for custom contractual terms'}>
                <Help sx={{ width: 20, ml: 0.5 }} />
              </Tooltip>
            </StyledBodyText>
            <StyledInput
              defaultValue={appStoreDownloads}
              error={invalidAppStoreDownloads}
              disabled={isReleased}
              required={false}
              onChange={this.handleAppStoreDownloadsChange}
            />
            {invalidAppStoreDownloads && (
              <StyledBodyText sx={{ fontSize: 12, m: 0.5 }} variant="bodyLower" color="error">
                {`Invalid downloads number`}
              </StyledBodyText>
            )}
          </Grid>
        </Grid>
        <Grid item className={`${classes.columnForm} ${classes.thirdColumn}`} xs={12} md={4} alignContent={'flex-start'}>
          <Grid item xs={12}>
            <StyledBodyText variant="h3" sx={{ marginY: 1.25 }} color="white80">
              Steam link
            </StyledBodyText>
            <StyledInput
              type="url"
              defaultValue={steamUrl}
              error={invalidSteamUrl}
              disabled={isReleased}
              required={false}
              onChange={this.handleSteamUrlChange}
            />
            {invalidSteamUrl && (
              <StyledBodyText sx={{ fontSize: 12, m: 0.5 }} variant="bodyLower" color="error">
                {`Invalid Steam link`}
              </StyledBodyText>
            )}
          </Grid>
          <Grid item xs={12}>
            <StyledBodyText variant="h3" sx={{ marginY: 1.25, display: 'flex' }} color="white80">
              Approximate # downloads
              <Tooltip title={'Games with a high number of downloads are eligible for custom contractual terms'}>
                <Help sx={{ width: 20, ml: 0.5 }} />
              </Tooltip>
            </StyledBodyText>
            <StyledInput
              defaultValue={steamDownloads}
              error={invalidSteamDownloads}
              disabled={isReleased}
              required={false}
              onChange={this.handleSteamDownloadsChange}
            />
            {invalidSteamDownloads && (
              <StyledBodyText sx={{ fontSize: 12, m: 0.5 }} variant="bodyLower" color="error">
                {`Invalid downloads number`}
              </StyledBodyText>
            )}
          </Grid>
        </Grid>
      </Grid>
    );
  }

  private handlePlayUrlChange = (evt: React.FocusEvent<HTMLInputElement>) => {
    const playUrl = valueFromInput(evt);
    this.props.updateInfoForm({ ...this.props.infoForm, playUrl });
  };

  private handlePlayStoreDownloadsChange = (evt: React.FocusEvent<HTMLInputElement>) => {
    const playStoreDownloads = valueFromInput(evt);
    this.props.updateInfoForm({ ...this.props.infoForm, playStoreDownloads });
  };

  private handleAppstoreUrlChange = (evt: React.FocusEvent<HTMLInputElement>) => {
    const appstoreUrl = valueFromInput(evt);
    this.props.updateInfoForm({ ...this.props.infoForm, appstoreUrl });
  };

  private handleAppStoreDownloadsChange = (evt: React.FocusEvent<HTMLInputElement>) => {
    const appStoreDownloads = valueFromInput(evt);
    this.props.updateInfoForm({ ...this.props.infoForm, appStoreDownloads });
  };

  private handleSteamDownloadsChange = (evt: React.FocusEvent<HTMLInputElement>) => {
    const steamDownloads = valueFromInput(evt);
    this.props.updateInfoForm({ ...this.props.infoForm, steamDownloads });
  };

  private handleSteamUrlChange = (evt: React.FocusEvent<HTMLInputElement>) => {
    const steamUrl = valueFromInput(evt);
    this.props.updateInfoForm({ ...this.props.infoForm, steamUrl });
  };

  private renderCategory() {
    const { tags, categories, isReleased, attemptedToSave, infoFormProblems } = this.props;
    const { category: selectedCategory, tags: selectedTags } = this.props.infoForm;
    const categoryError = attemptedToSave && infoFormProblems?.includes('CATEGORY_MISSING');
    const tagError = attemptedToSave && infoFormProblems?.includes('TAG_MISSING');
    return (
      <>
        <StyledBodyText variant="h3" sx={{ mt: 3, mb: 1 }} color="white80">
          Category <span>*</span>
        </StyledBodyText>
        <CategorySelector
          disabled={isReleased}
          error={categoryError}
          categories={categories}
          selected={selectedCategory}
          onChange={this.handleCategoryChange}
        />
        {categoryError && (
          <StyledBodyText sx={{ fontSize: 12, m: 0.5 }} variant="bodyLower" color="error">
            {`Please select a category`}
          </StyledBodyText>
        )}

        <StyledBodyText variant="h3" sx={{ marginY: 1.25 }}>
          Tags <span>*</span>{' '}
          <span style={{ fontSize: 12, color: COLORS.white[50], fontWeight: 400, marginLeft: 8 }}>MAX. {MAX_NO_TAGS}</span>
        </StyledBodyText>
        <TagsSelector error={tagError} tags={tags} selected={selectedTags} onChange={this.handleTagsChange} disabled={isReleased} />
        {tagError && (
          <StyledBodyText sx={{ fontSize: 12, m: 0.5 }} variant="bodyLower" color="error">
            {`Please select at least 1 tag`}
          </StyledBodyText>
        )}
      </>
    );
  }

  private handleCategoryChange = (category: string | null) => {
    this.props.updateInfoForm({ ...this.props.infoForm, category });
  };

  private handleTagsChange = (tags: string[]) => {
    this.props.updateInfoForm({ ...this.props.infoForm, tags });
  };

  private renderDescription() {
    const { description, controls } = this.props.infoForm;
    const { isReleased, theme, attemptedToSave, infoFormProblems } = this.props;

    const descriptionMissing = attemptedToSave && infoFormProblems?.includes('DESCRIPTION_MISSING');
    const descriptionTooLong = infoFormProblems?.includes('DESCRIPTION_TOO_LONG');
    const controlMissing = attemptedToSave && infoFormProblems?.includes('CONTROLS_MISSING');
    const controlTooLong = infoFormProblems?.includes('CONTROLS_TOO_LONG');

    const descriptionError = descriptionMissing || descriptionTooLong;
    const controlError = controlMissing || controlTooLong;

    return (
      <div style={{ marginTop: theme.spacing(3) }}>
        <StyledBodyText variant="h3" sx={{ marginY: 1.25 }} color="white80">
          Description <span>*</span>{' '}
          <span style={{ fontSize: 12, color: COLORS.white[50], fontWeight: 400, marginLeft: 8 }}>NO HTML ALLOWED</span>
        </StyledBodyText>
        <StyledInput
          value={description || ''}
          error={descriptionError}
          disabled={isReleased}
          required={true}
          multiline={true}
          onChange={this.handleDescriptionChange}
        />
        {descriptionMissing && (
          <StyledBodyText sx={{ fontSize: 12, m: 0.5 }} variant="bodyLower" color="error">
            {`Please fill in the input `}
          </StyledBodyText>
        )}
        {descriptionTooLong && (
          <StyledBodyText sx={{ fontSize: 12, m: 0.5 }} variant="bodyLower" color="error">
            {`Please limit your input to ${MAX_DESCRIPTION_LENGTH} characters`}
          </StyledBodyText>
        )}
        <StyledBodyText variant="h3" sx={{ marginY: 1.25 }}>
          Controls <span>*</span>
        </StyledBodyText>
        <StyledInput
          error={controlError}
          value={controls || ''}
          disabled={isReleased}
          required={true}
          multiline={true}
          onChange={this.handleControlsChange}
        />
        {controlMissing && (
          <StyledBodyText sx={{ fontSize: 12, m: 0.5 }} variant="bodyLower" color="error">
            {`Please fill in the input `}
          </StyledBodyText>
        )}
        {controlTooLong && (
          <StyledBodyText sx={{ fontSize: 12, m: 0.5 }} variant="bodyLower" color="error">
            {`Please limit your input to ${MAX_CONTROLS_LENGTH} characters`}
          </StyledBodyText>
        )}
      </div>
    );
  }

  private handleDescriptionChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const description = valueFromInput(evt);
    this.props.updateInfoForm({ ...this.props.infoForm, description });
  };

  private handleControlsChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const controls = valueFromInput(evt);
    this.props.updateInfoForm({ ...this.props.infoForm, controls });
  };
}

export default withTheme(withSubmission(GameInfoForm));
