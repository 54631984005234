import { useQuery } from '@apollo/client';
import { i18n } from '@lingui/core';
import { CircularProgress, Typography, useTheme } from '@mui/material';
import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from 'chart.js';
import moment from 'moment';
import numeral from 'numeral';
import * as React from 'react';
import { Bar } from 'react-chartjs-2';
import { StatsPeriod } from '../../../../../../common/components/SelectTimeframe/SelectTimeframe';
import { formatAmount, formatDurationAsHMS, PERCENTAGE_FORMAT } from '../../../../../../common/formatting';
import { DeveloperDailyRevenueStat } from '../../Games/userDeveloperSubmission-graphql';
import { DeveloperDailyStat, MY_GAME_DAILY_STATS_QUERY, MyGameDailyStatsQuery, Submission } from '../submission-graphql';
import { DeveloperSubmissionStatsMetric } from './DeveloperSubmissionStats';
import { Tooltip as MuiTooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

interface DeveloperSubmissionStatsDailyBarProps {
  submission: Submission;
  timeframeFilter: StatsPeriod;
  selectedMetric: DeveloperSubmissionStatsMetric;
}

const DeveloperSubmissionStatsDailyBar: React.FunctionComponent<DeveloperSubmissionStatsDailyBarProps> = ({
  submission,
  timeframeFilter,
  selectedMetric, // Destructure selectedMetric
}) => {
  const theme = useTheme();

  // If timeframeFilter.period is 'ALL_TIME', limit the timeframe.options to 1 year.
  const safeTimeframeFilter = React.useMemo(() => {
    if (timeframeFilter.period === 'ALL_TIME') {
      return {
        ...timeframeFilter,
        options: {
          ...timeframeFilter.options,
          from: moment().subtract(1, 'year').format('YYYY-MM-DD'),
        },
      };
    }
    return timeframeFilter;
  }, [timeframeFilter]);

  const { loading, data: dailyGameData } = useQuery<MyGameDailyStatsQuery>(MY_GAME_DAILY_STATS_QUERY, {
    variables: { slug: submission.slug, options: safeTimeframeFilter.options },
  });

  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', paddingTop: theme.spacing(4) }}>
        <CircularProgress />
      </div>
    );
  }

  const getChartRawData = (): DeveloperDailyStat[] | DeveloperDailyRevenueStat[] => {
    if (!dailyGameData) {
      return [];
    }
    if (selectedMetric === 'revenue') {
      return [...dailyGameData.gameBySlug.dailyRevenueStats].reverse();
    }
    return dailyGameData.gameBySlug.dailyStats;
  };

  const getYAxisLabel = (): string => {
    switch (selectedMetric) {
      case 'pageviews':
        return 'Total plays';
      case 'avgTimeOnPage':
        return 'Average playtime (sec)';
      case 'retentionD1':
        return 'Retention D1 (%)';
      case 'retentionD7':
        return 'Retention D7 (%)';
      case 'rating':
        return 'Rating';
      case 'revenue':
        return 'My revenue (EUR)';
      case 'users':
        return 'Total players';
      case 'returningPlayers':
        return 'Returning users (%)';
      case 'mobileUsers':
        return 'Mobile users (%)';
      case 'avgConversion':
        return 'Gameplay conversion (%)';
      case 'avgLoadingTime':
        return 'Avg loading time (sec)';
      case 'issues':
        return 'Issues / 1k plays';
      default:
        return '';
    }
  };

  const chartRawData = getChartRawData();
  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (context: any) => {
            const getMetricLabel = (): string => {
              switch (selectedMetric) {
                case 'pageviews':
                case 'users':
                case 'issues':
                  return `${formatAmount(context.parsed.y)}`;
                case 'avgTimeOnPage':
                  return `${formatDurationAsHMS(moment.duration(context.parsed.y, 'seconds'))}`;
                case 'retentionD1':
                case 'retentionD7':
                case 'returningPlayers':
                case 'mobileUsers':
                case 'avgConversion':
                  return `${numeral(context.parsed.y).format(PERCENTAGE_FORMAT)}%`;
                case 'rating':
                  return `${context.parsed.y.toFixed(1)}`;
                case 'revenue':
                  return `${context.parsed.y.toLocaleString('nl-BE', { style: 'currency', currency: 'EUR' })}`;
                case 'avgLoadingTime':
                  return `${i18n.number(context.parsed.y, { notation: 'compact' })} sec`;
                default:
                  return '';
              }
            };

            const metricLabel = getMetricLabel();
            return metricLabel;
          },
        },
      },
    },
    scales: {
      y: {
        title: {
          display: true,
          text: getYAxisLabel(),
        },
      },
    },
  };

  const mappedData = chartRawData.map((stats: DeveloperDailyStat | DeveloperDailyRevenueStat) => {
    switch (selectedMetric) {
      case 'pageviews':
        return (stats as DeveloperDailyStat).pageviews;
      case 'avgTimeOnPage':
        return (stats as DeveloperDailyStat).avgTimeOnPage;
      case 'retentionD1':
        return (stats as DeveloperDailyStat).retentionD1 * 100;
      case 'retentionD7':
        return (stats as DeveloperDailyStat).retentionD7 * 100;
      case 'rating':
        return null;
      case 'revenue':
        return (stats as DeveloperDailyRevenueStat).revenueShareDaily.total;
      case 'users':
        return (stats as DeveloperDailyStat).users;
      case 'returningPlayers':
        return (stats as DeveloperDailyStat).returningPlayers * 100;
      case 'mobileUsers':
        return (stats as DeveloperDailyStat).users
          ? ((stats as DeveloperDailyStat).mobileUsers / (stats as DeveloperDailyStat).users) * 100
          : 0;
      case 'avgConversion':
        return (stats as DeveloperDailyStat).avgConversion * 100;
      case 'avgLoadingTime':
        return (stats as DeveloperDailyStat).avgLoadingTime;
      case 'issues':
        return (stats as DeveloperDailyStat).issues;
      default:
        return 0;
    }
  });
  const allEmpty = mappedData.length === 0 || mappedData.every((value) => value === 0);
  const unsupportedMetric = selectedMetric === 'rating';

  const labels = chartRawData.map((stats: DeveloperDailyStat | DeveloperDailyRevenueStat) => moment(stats.date).format('Do MMM'));
  const chartData = {
    labels,
    datasets: [
      {
        data: mappedData,
        backgroundColor: '#1BECBA',
      },
    ],
  };

  const tooltipText = timeframeFilter.period === 'ALL_TIME' ? 'The data in this chart is limited to the past year' : null;

  return (
    <div style={{ padding: theme.spacing(2) }}>
      {tooltipText && (
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <MuiTooltip title={tooltipText}>
            <InfoIcon style={{ height: 16, width: 16, marginLeft: 4 }} />
          </MuiTooltip>
        </div>
      )}
      {allEmpty || unsupportedMetric ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          <Typography style={{ padding: '16px', textAlign: 'center' }} variant="body1">
            {unsupportedMetric ? 'No timeseries data available for this metric' : 'No data available for the selected timeframe'}
          </Typography>
        </div>
      ) : (
        <Bar options={chartOptions} data={chartData} />
      )}
    </div>
  );
};

export default DeveloperSubmissionStatsDailyBar;
